<template>
  <div class="hello">
    <div class="title">
      <p>Subscribe to our mailing list for getting the latest updates</p>
    </div>
    <form ref="form" class="form" @submit="onSubmit" @submit.prevent>
      <input
        type="email"
        :disabled="isSentEmail"
        v-model="email"
        name="email"
        class="input"
        placeholder="Email@Address"
        required
      />
      <button
        type="submit"
        :disabled="loading || isSentEmail"
        class="submit"
      >
        <EnvelopeLogo
          :color="!isSentEmail ? '#fff' : '#7676764d'"
          class="submit-logo"
        />
      </button>
    </form>
    <h2 class="text-white" v-if="isSentEmail">
      You have been successfully subscribed to email list
    </h2>
  </div>
</template>

<script>
import EnvelopeLogo from "./EnvelopeLogo.vue";
import { sendEmail } from "@/service/email";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    EnvelopeLogo,
  },

  mounted() {
    console.log(localStorage.getItem("isSentEmail"));
    if (localStorage.getItem("isSentEmail") == "true") {
      this.isSentEmail = true;
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      if(this.email === "") { 
        alert('Please enter a valid email.')
        return
      }
      sendEmail(this.email)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("isSentEmail", true);
            this.isSentEmail = true;
          } else {
            console.log("Someting went wrong");
            alert("Someting went wrong")
          }
        })
        .catch(() => {
          console.log("Someting went wrong");
          alert("Someting went wrong")
        })
        .finally(() => {
          this.$refs.form.reset();
          this.loading = false;
        });
    },
  },
  data() {
    return {
      email: "",
      isSentEmail: false,
      loading: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.input {
  background: transparent;
  color: white;
  border: 3px solid white;
  width: 17em;
  height: 40px;
  font-size: 1.4em;
  padding-left: 0.5em;
  /* border-radius: 5px; */
  border-radius: 5px 0 0 5px;
}
.input::placeholder {
  opacity: 1;
  font-weight: 300;
  color: white;
}
.input:focus {
  border: 0;
}
.input:focus-visible {
  border: 0;
  outline: unset;
  border: 3px solid white;
  padding-left: 0.5em;
  border-radius: 5px 0 0 5px;
}
.input:active {
  background-color: inherit;
}
.form {
  display: flex;
  justify-content: center;
}
.submit-logo {
  width: 2.5em;
}
.submit {
  background-color: transparent;
  border-color: white;
  border: 3px solid white;
  border-left: 0px;
  border-radius: 0 5px 5px 0;
}
.title p {
  color: white;
  margin: 4px;
}
input:disabled {
  cursor: default;
  background-color: rgba(118, 118, 118, 0.3);
  color: rgba(118, 118, 118, 0.3);
  border-color: rgba(118, 118, 118, 0.3);
}
.input:disabled::placeholder {
  color: black;
}
.submit:disabled {
  color: unset;
  border-color: rgba(118, 118, 118, 0.3);
  background-color: rgba(118, 118, 118, 0.3);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black;
}
input:-webkit-autofill {
  /* -webkit-text-fill-color: white !important; */
}
.text-white {
  color: white;
}
</style>
