<template>
  <div class="background">
    <video class="video"  autoplay="autoplay" playsinline preload loop muted>
      <source src="/MuteVideo.mp4" type="video/mp4" />
    </video>
  </div>
  <div class="content">
    <div class="heading">
      <img alt="Castify Logo"  class="logo" src="/castify.svg" />
      <div class="sub-title">
        <h3>Pek Yakında... Coming Soon... Próximamente... قريباً</h3>
      </div>
    </div>

    <HelloWorld class="foot" msg="Welcome to Castify" />
    <div class="text-white">Some rights reserved © Castify 2023</div>
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
  methods: { 
    startVideo () {
      if(document.querySelector("video").paused) { 
          document.querySelector("video").play();
        console.log("is video running=", !document.querySelector("video").paused)
        }
    }
  },
  mounted() {
    try { 
      document.querySelector("video").play();
    }catch(e) {
      console.log 
    }

    setTimeout(()=> { 
      document.querySelector("video").play();
      console.log(document.querySelector("video"))
    },0)
    document.body.addEventListener('click', ()=>{ 
      this.startVideo()
      })
      document.body.addEventListener('touchstart', ()=>{ 
      this.startVideo()
      })
  },
};
</script>

<style>
body {
  margin: 0px;
  height: 100vh;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  overflow: hidden;
}
.content {
  padding: 4em;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: calc(87%);
  padding-bottom: 4em;
}
.logo {
  width: 300px;
  height: 130px;
  filter: invert(1);
}
.background {
  position: absolute;
  z-index: -1;
  height: 100vh;
  overflow: hidden;
}
.background .video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.sub-title h3 {
  margin-top: 0px;
  color: white;
}
* {
  font-family: "Poppins", sans-serif !important;
}
.foot {
  margin-top: 10em;
  margin-bottom: 3em;
  transition: 1000ms;
}
.text-white {
  color: white;
}
@media screen and (max-width: 700px) {
  .content {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 470px) {
  .content {
    font-size: 0.6em;
  }
  .foot {
    margin-bottom: 7em;
  }
}
@media screen and (max-width: 300px) {
  .content {
    font-size: 0.5em;
  }
  .foot {
    margin-bottom: 8em;
  }
}
@media screen and (max-height: 550px) {
  .content {
    height: calc(70%);
  }
  .foot{ 
    margin-top: 0em;
  }
  * {
    margin-bottom: 0px !important;
  }
}


</style>
